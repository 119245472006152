// Manual reset
export const SELFCARE_SET_PASSWORD = "SELFCARE_SET_PASSWORD";
export const SELFCARE_SET_PASSWORD_ERROR = "SELFCARE_SET_PASSWORD_ERROR";

// Reset by triggering email
export const SELFCARE_RESET_PASSWORD = "SELFCARE_RESET_PASSWORD";
export const SELFCARE_RESET_PASSWORD_SUCCESS =
  "SELFCARE_RESET_PASSWORD_SUCCESS";
export const SELFCARE_RESET_PASSWORD_FAILED = "SELFCARE_RESET_PASSWORD_FAILED";
export const SELFCARE_RESET = "SELFCARE_RESET";
export const SELFCARE_VERIFICATION_PASSWORD_SAVE =
  "SELFCARE_VERIFICATION_PASSWORD_SAVE";
export const SELFCARE_VERIFICATION_PASSWORD_SAVE_FAILED =
  "SELFCARE_VERIFICATION_PASSWORD_SAVE_FAILED";
export const SELFCARE_VERIFICATION_PASSWORD_SAVE_WRONG_TOKEN =
  "SELFCARE_VERIFICATION_PASSWORD_SAVE_WRONG_TOKEN";
