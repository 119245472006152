// All defaults which will be used in multiple files
export const API_ERROR =
  "Leider ist ein Fehler aufgetreten. Bitte versuche es erneut.";

export const API_VALIDATION = "Deine Eingabe ist nicht korrekt.";

export const API_VALIDATION_LAST_TRY_ACCOUNT_BLOCKED =
  "Deine Eingabe ist nicht korrekt. Du hast noch einen Versuch bevor dein Zugang gesperrt wird.";

export const API_VALIDATION_MPP =
  "Deine Eingabe ist nicht korrekt. Deine Änderungen wurden nicht gespeichert.";
