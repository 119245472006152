// Types based in https://sky.atlassian.net/wiki/spaces/CRM/pages/796393570/Logging+Errors+etc.
export const LOGGER_TYPE_INFO = "INFO";
export const LOGGER_TYPE_NOTICE = "NOTICE";
export const LOGGER_TYPE_ERROR = "ERROR";
export const LOGGER_TYPE_DEBUG = "DEBUG";
export const LOGGER_TYPE_WARNING = "WARNING";
export const LOGGER_TYPE_CRITICAL = "CRITICAL";
export const LOGGER_TYPE_ALERT = "ALERT";
export const LOGGER_TYPE_EMERGENCY = "EMERGENCY";

// Prefixes for internal reporting
export const LOGGER_PREFIX_MEASURE = "MEASURE";

// Measure types
export const LOGGER_MEASURE_TYPE_REQUEST = "request";
export const LOGGER_MEASURE_TYPE_RENDER = "render";

// Texts
export const LOGGER_UNTITLED_ERROR = "An error ocurred";
