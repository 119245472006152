// Modules
import { useState, useEffect } from "react";

// Settings
export const SKY_WINDOW_KEY = "SKY_CONFIG";
export const CONFIG_EVENT = "sky-config-loaded";

// Hook
export const useConfig = () => {
  // State
  const [configLoaded, setConfigLoaded] = useState(false);

  // Hooks
  useEffect(() => {
    // If it is not loaded then wait for the event
    if (!window[SKY_WINDOW_KEY]) {
      // Add an event listener
      document.addEventListener(CONFIG_EVENT, () => setConfigLoaded(true));
    } else {
      // Config is loaded. We do not have to wait for an event.
      setConfigLoaded(true);
    }

    // Remove listener on unmount
    return () => {
      document.removeEventListener(CONFIG_EVENT, () => setConfigLoaded(true));
    };
  }, []);

  return configLoaded;
};

// Return the config by the root key
export const getConfig = (type = "") => {
  const config = window[SKY_WINDOW_KEY] || {};
  return config[type] || {};
};
