// Constants
import { API_IDENTITY_DIGIE_VERIFICATION, API_LOGS } from "constants/api";

// Middleware
import { request } from "./switcher";

export const digiELogin = ({ deviceCode, email, password }) =>
  request({
    type: "digielogin",
    url: API_IDENTITY_DIGIE_VERIFICATION,
    method: "POST",
    data: {
      deviceCode,
      username: email,
      password
    }
  });

// Logs will be written to GCP and have a separate header so we have to pass
// the property isLogging
export const writeLogs = data =>
  request({
    type: "writelogs",
    url: API_LOGS,
    method: "POST",
    data,
    isLogging: true
  });
