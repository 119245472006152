// Modules
import React from "react";

// Toolkit Component
import { Spinner } from "@sky-uk/toolkit-react";

// CSS
import "./style.scss";

//Custom Component
export default ({
  id,
  inverse, //Boolean value to change the color to white.
  overlay, // String (clear,light, or dark), default value is: clear.
  size, // String (small, medium, or large), default value is: medium.
  alignCenter, //Boolean to align the spinner in the center.
  complete //Boolean to fade-out and hide the spinner.
}) => {
  return (
    <Spinner
      id={id}
      inverse={inverse}
      overlay={overlay}
      size={size}
      alignCenter={alignCenter}
      complete={complete}
      className="spinner"
    />
  );
};
