// Modules
import React, { useState } from "react";
import { Accordion, AccordionSection } from "@sky-uk/toolkit-react";

// CSS
import "./dataPrivacy.page.scss";

// prettier-ignore
export default () => { // NOSONAR
  const baseClass = "data-privacy";
  const [accordionSelected, setAccordionSelected] = useState([]);

  return (
    <div className={baseClass} data-test-id={baseClass}>
      <div className="o-layout o-layout--center">
        <div className="o-layout__item u-width-1/1  u-width-8/12@large u-padding-left-none">
          <h1 className="c-heading-charlie u-margin-top u-text-center">
            Datenschutzerklärung
          </h1>

          <div className="c-text-body u-text-left">
            <p>
            Der Schutz Ihrer persönlichen Daten und Ihrer Privatsphäre ist uns, Sky, sehr wichtig. Diese Datenschutzerklärung gilt für personenbezogene Daten, die wir auf dieser Webseite von Ihnen verarbeiten,. Im Folgenden finden Sie Informationen dazu, wer wir sind und wie Sie uns kontaktieren können. Wir erklären auch, wie und warum wir personenbezogene Daten von Ihnen verarbeiten, an wen wir sie übermitteln und wie lange wir sie speichern. Abschließend informieren wir Sie über die Rechte, die Ihnen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten durch uns zustehen.
            </p>{" "}
            <Accordion
              id="accordion"
              selected={accordionSelected}
              updateSelection={setAccordionSelected}
            >
              <AccordionSection
                id="1"
                title="1. Wer wir sind und wie Sie uns kontaktieren können"
              >
                <p>
                  Wir, die Sky Deutschland Fernsehen GmbH & Co.KG, Medienallee
                  26, 85774 Unterföhring, sind Verantwortlicher für die
                  Verarbeitung Ihrer personenbezogenen Daten im Zusammenhang mit
                  der Nutzung unserer Produkte und Services unter{" "}
                  <a
                    className="c-link-external"
                    href="http://www.sky.de"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    www.sky.de
                  </a>
                  .{" "}
                </p>
                <p>
                  Wir haben einen Datenschutzbeauftragten bestellt, den Sie
                  unter{" "}
                  <a href="mailto:datenschutz@sky.de">datenschutz@sky.de</a>{" "}
                  oder per Post unter Sky Deutschland Fernsehen GmbH & Co.KG,
                  Datenschutz, Medienallee 26, 85774 Unterföhring erreichen
                  können.
                </p>
              </AccordionSection>
              <AccordionSection
                id="2"
                title="2. Wie und warum wir Ihre personenbezogenen Daten verarbeiten"
              >
                <p>
                  Im Folgenden informieren wir Sie darüber, welche
                  personenbezogenen Daten wir von Ihnen auf dieser Webseite. Wir
                  erklären außerdem, zu welchem Zweck wir diese Daten nutzen und
                  auf welcher rechtlichen Grundlage.
                </p>
                <p>
                  2.1 Daten, die wir auf Grundlage einer Einwilligung
                  verarbeiten, Art. 6 Abs. 1a) DSGVO{" "}
                </p>
                <p>2.1.1. Social Media - Aufritte / Nutzung von Fanseiten in sozialen Netzwerken</p>
                <p>
                Sofern Sie soziale Netzwerke nutzen, richtet sich Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch die sozialen Netzwerke sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre grundsätzlich nach den Datenschutzhinweisen des jeweiligen sozialen Netzwerks:
                </p>
                <ul>
                  <li>
                    <p>
                    Facebook, Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland (“Facebook”)
                    </p>{" "}
                    <p>
                      Datenschutzerklärung abrufbar unter:{" "}
                      <a
                        className="c-link-external"
                        href="https://www.facebook.com/help/203587239679209"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        https://www.facebook.com/help/203587239679209
                      </a>, {" "} 
                      <a
                        className="c-link-external"
                        href="http://de-de.facebook.com/policy.php"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        http://de-de.facebook.com/policy.php
                      </a>
                    </p>
                    <p>
                    Bei Fragen zur Wahrung Ihrer Rechte als betroffene Person können Sie sich direkt an Facebook oder Sky wenden. Die primäre Verantwortung gemäß DSGVO für die Verarbeitung der sog. Insights-Daten von Facebook liegt bei Facebook. Entsprechend werden die Pflichten nach DSGVO von Facebook erfüllt. Facebook stellt den betroffenen Personen die wesentlichen Inhalte der Vereinbarung bzgl. der Insights-Daten zwischen Facebook und dem Fanpage – Betreiber zur Verfügung. Daraus ergibt sich insbesondere, dass Sky als Betreiber der Fanpage keine Entscheidungen hinsichtlich der Verarbeitung von Insights-Daten und aller weiteren sich aus Art. 13 DSGVO ergebenen Informationen hierzu, darunter Rechtsgrundalge, Identität des Verantwortlichen und Speicherdauer von Cookies auf Nutzerendgeräten, trifft.
                    </p>{" "}
                  </li>
                </ul>
                <p>
                  2.2. Daten, die wir für die Erfüllung eines Vertrages
                  verarbeiten, Art. 6 Abs. 1b) DSGVO
                </p>
                <p>2.2.1 Kontaktaufnahme</p>
                <p>
                  Wenn Sie uns kontaktieren möchten, benötigen Sie entweder ein Facebook Messenger- , ein Whatsapp Konto oder eine SMS – Funktion auf Ihrem Mobilgerät. Für die Nutzung des Facebook Messenger gelten die Datenschutzbestimmungen der Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA, die unter{" "}
                  <a
                    className="c-link-external"
                    href="https://www.facebook.com/about/privacy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    https://www.facebook.com/about/privacy
                  </a>{" "}
                  einsehbar sind. Für Whatsapp gelten die Datenschutzbestimmungen von Whatsapp, Whats App Inc., 650 Castro Street, Suite 120-219 Mountain View, CA 94041, USA, die unter{" "}
                  <a
                    className="c-link-external"
                    href="https://www.whatsapp.com/legal/#privacy-policy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    www.whatsapp.com/legal/#Privacy
                  </a>{" "}
                  einsehbar sind.{" "}
                </p>
                <p>
                  Zur Optimierung unseres Kundenservice setzen wir bei der
                  Chat-Kommunikation teilweise einen sogenannten "Chatbot" ein.
                  Dieser ordnet anhand der von Ihnen eingegebenen Informationen
                  Ihr Anliegen automatisiert zu und generiert Antwortvorschläge
                  für unsere Servicemitarbeiter, um Ihnen insbesondere bei
                  Standardanliegen möglichst schnell und unkompliziert
                  Hilfestellung geben zu können.
                </p>
                <p>
                  Grundsätzlich können Sie sich mit allgemeinen Anfragen über den Facebook Messenger/ Whatsapp an Sky wenden. Hierfür ist es grundsätzlich nicht notwendig sich als Kunde zu verifizieren. 
                </p>
                <p>
                  Die Bereitstellung von Kontakt- und (sofern Sie Sky Kunde sind) von Vertragsdaten wie Ihre Kundennummer kann jedoch erforderlich werden, um Ihr Anliegen bearbeiten. Andernfalls könnten wir Ihre Anfrage leider nicht bzw. nicht zufriedenstellend bearbeiten. In diesem Fall werden Sie darum gebeten sich zur Verifizierung Ihrer Person einzuloggen. Sofern dies erfolgreich durchgeführt wird verarbeiten und speichern wir die im Rahmen des Chats zur Verfügung gestellten Daten (z.B. Kundennummer, Name, Pakete), Datum und Uhrzeit der Anfrage sowie die zugehörige Facebook ID  bzw. Telefonnummer zur Identifizierung des Eingangskanals des Anliegens zu ihrem Kundenaccount, um ihr Anliegen bearbeiten zu können. Eine über die Klärung ihres Anliegens hinausgehende Verwendung der Facebook ID/Telefonnummer findet nicht statt.  
                </p>
                <p>
                  2.2.2 Daten die wir für die Erfüllung einer rechtlichen
                  Verpflichtung verarbeiten, Art. 6 Abs. 1 c) DSGVO
                </p>
                <p>
                  Sie gelangen auf diese Webseite, weil Sie sich über den
                  Facebook Messenger/Service Chat an Sky gewendet haben und für
                  die Beantwortung und Bearbeitung ihres Anliegens eine
                  Zuordnung zu ihrem Kundenaccount notwendig ist. Um eine
                  Zuordnung zu Ihrem Kundenaccount vornehmen zu können, ist es
                  notwendig, dass sie sich durch Eingabe ihrer Sky Zugangsdaten
                  verifizieren. Dies ist eine zwingend erforderliche Maßnahme
                  die die Datenschutzgrundverordnung für die Sicherheit der
                  Kundendaten vorschreibt.
                </p>
              </AccordionSection>
              <AccordionSection
                id="3"
                title="3. An wen wir personenbezogene Daten übermitteln"
              >
                <p>
                  In diesem Absatz erklären wir, an wen wir personenbezogene
                  Daten im Zusammenhang mit der Klärung Ihres Anliegens und der
                  Verifikation Ihrer Person übermitteln und, sofern wir Daten in
                  ein Drittland bzw. an eine internationale Organisation
                  übermitteln, wie wir das tun.
                </p>
                <p>
                  3.1 An wen wir Daten im Rahmen der Abwicklung des
                  Abonnementsvertrages übermitteln
                </p>
                <p>
                  Abhängig vom jeweiligen Abonnement übermitteln wir
                  personenbezogene Daten an folgende Empfänger:
                </p>
                <p>
                  I.
                  <span className="underline u-text-bold">
                    Empfänger: Unternehmen der Sky Gruppe
                  </span>
                </p>
                <p>Zweck der Übermittlung:</p>
                <p>für interne Verwaltungszwecke</p>
                <p>
                  Übermittlung in ein Drittland bzw. an eine internationale
                  Organisation:
                </p>
                <p>
                  Eine Übermittlung Ihrer personenbezogenen Daten in
                  Drittstaaten wird derzeit nicht durchgeführt und ist auch
                  nicht geplant.
                </p>
                <p>
                  II.
                  <span className="underline u-text-bold">
                    Empfänger: Sky Dienstleister
                  </span>
                </p>
                <p>Zweck der Übermittlung:</p>
                <p>
                  Zu Zwecken der Vertragsdurchführung und Klärung ihres
                  Anliegens, sofern diese Dienstleister im Auftrag von Sky
                  Leistungen erbringen, die für die Abwicklung des
                  Abonnementverhältnisses – insbesondere zur Klärung ihres
                  Kundenanliegens - erforderlich sind; im Rahmen dieser sog.
                  Verarbeitung im Auftrag (Art. 28 DS-GVO) stellen wir durch
                  strenge vertragliche Regelungen, technische und
                  organisatorische Maßnahmen sowie ergänzende Kontrollen den
                  Schutz Ihrer Daten sicher.
                </p>
                <p>
                  Übermittlung in ein Drittland bzw. an eine internationale
                  Organisation:
                </p>
                <p>
                  Sofern sich ein Sky Dienstleister in einem Drittland befindet,
                  wird durch geeignete Maßnahmen (insbesondere Verwendung von EU
                  -Standardvertragsklauseln) gewährleistet, dass Ihre Rechte als
                  betroffene Person gewahrt sind.
                </p>
                <p>
                  III.
                  <span className="underline u-text-bold">
                    Empfänger: Öffentliche Stellen
                  </span>
                </p>
                <p>Zweck der Übermittlung:</p>
                <p>Zur Erfüllung vorrangiger gesetzlicher Verpflichtungen.</p>
                <p>
                  Übermittlung in ein Drittland bzw. an eine internationale
                  Organisation:
                </p>
                <p>
                  Eine Übermittlung Ihrer personenbezogenen Daten in
                  Drittstaaten wird derzeit nicht durchgeführt und ist auch
                  nicht geplant.
                </p>
                <p>3.2 Wie lange wir Ihre personenbezogenen Daten speichern</p>
                <p>
                  Im Folgenden möchten wir Sie darüber informieren, wie lange
                  wir personenbezogene Daten von Ihnen speichern oder, falls die
                  Angabe eines konkreten Zeitraums nicht möglich ist, wie die
                  Kriterien für die Festlegung der Speicherdauer sind.
                </p>
                <p>
                  Wir bewahren Ihre Daten über die folgenden Zeiträume auf; nach
                  Ablauf dieser Fristen werden die entsprechenden Daten
                  routinemäßig gelöscht.
                </p>
                <p>
                  I.
                  <span className="underline u-text-bold">
                    Art der Daten: Vertragsdaten
                  </span>
                </p>
                <p>
                  Z.B. Kaufgegenstand, Name, Postanschrift, E-Mail-Adresse,
                  Lieferanschrift, Bezahlweise und Bankdaten
                </p>
                <p>Aufbewahrungsfrist:</p>
                <p>
                  10 Jahre, beginnend mit Ende des Kalenderjahres, in dem der
                  Vertrag beendet/der Dienst letztmalig genutzt wurde
                </p>
                <p>
                  II.
                  <span className="underline u-text-bold">
                    Art der Daten: Geschäftsbriefe (z.B. Briefpost, Emails)
                  </span>
                </p>
                <p>Aufbewahrungsfrist:</p>
                <p>
                  6 Jahre, beginnend mit Ende des Kalenderjahres, in dem der
                  Geschäftsbrief empfangen bzw. abgesandt worden ist.
                </p>
                <p>
                  Sofern Daten hiervon nicht berührt sind, werden die Daten
                  gelöscht, wenn die Zwecke wegfallen, für die sie erhoben
                  wurden, oder wenn Sie eine Einwilligung, auf der eine
                  Datenverarbeitung beruht, widerrufen.
                </p>
                <p>
                  Für den Fall, dass eine Löschung aus rechtlichen, technischen
                  oder organisatorischen Gründen nicht oder nur mit
                  unverhältnismäßigem Aufwand möglich ist, wird die Verarbeitung
                  Ihrer Daten eingeschränkt.
                </p>
              </AccordionSection>
              <AccordionSection id="4" title="4. Ihre Rechte">
                <p>
                  Im Folgenden möchten wir Sie auf die Rechte, die Ihnen gem.
                  Art. 15ff DSGVO im Hinblick auf die Verarbeitung Ihrer
                  personenbezogenen Daten zustehen, hinweisen:
                </p>
                <ul>
                  <li>
                    Sie haben das Recht, unentgeltlich Auskunft über Ihre von
                    uns gespeicherten personenbezogenen Daten zu verlangen.
                  </li>
                  <li>
                    Sie haben das Recht, die Berichtigung, Löschung („Recht auf
                    Vergessenwerden“) oder Einschränkung der Verarbeitung Ihrer
                    personenbezogenen Daten zu verlangen.
                  </li>
                  <li>
                    Sie haben das Recht, einer Datenverarbeitung, die zur
                    Wahrung berechtigter Interessen von Sky oder eines Dritten
                    erforderlich ist, oder die zum Zweck der Direktwerbung
                    erfolgt, im Rahmen der gesetzlichen Bestimmungen zu
                    widersprechen.
                  </li>
                  <li>
                    Sie haben das Recht, die Sie betreffenden Daten in einem
                    strukturierten, gängigen und maschinenlesbaren Format zu
                    erhalten (sog. „Recht auf Datenübertragbarkeit“).
                  </li>
                </ul>
                <p>
                  Sofern Sie eines dieser Rechte ausüben möchten, kontaktieren
                  Sie uns bitte wie unter Ziffer 1 beschrieben. Wenn Sie der
                  Ansicht sind, dass die Verarbeitung der Sie betreffenden
                  personenbezogenen Daten durch Sky einschlägigen gesetzlichen
                  Bestimmungen widerspricht, können Sie sich auch an eine
                  Aufsichtsbehörde, insbesondere in dem Mitgliedsstaat Ihres
                  Aufenthaltsorts oder des Orts des mutmaßlichen Verstoßes,
                  wenden.
                </p>
              </AccordionSection>
            </Accordion>
            <p className="u-text-italic">Version 1.1 Stand Juli 2020</p>
          </div>
        </div>
      </div>
    </div>
  );
};
