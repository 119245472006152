// Modules
import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Store
import { LOGGER_WRITE_LOG } from "store/actions/logger";

// Constants
import { LOGGER_TYPE_ERROR } from "constants/logger";

// Hook
export default () => {
  // Setup
  const dispatch = useDispatch();

  // Init the logger
  useEffect(() => {
    // Functions inside hook
    const writeLog = (message, severity) => {
      // Send logs to server
      dispatch({ type: LOGGER_WRITE_LOG, payload: { message, severity } });
    };

    // Handle all js errors on the page
    const handleError = error => {
      writeLog(error.message, LOGGER_TYPE_ERROR);
    };

    // Bind listeners
    window.addEventListener("error", handleError);

    return () => {
      // Unbind all listeners
      window.removeEventListener("error", handleError);
    };
  }, [dispatch]);
};
