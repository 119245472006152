// Services
import { getRequest, putRequest, postRequest, deleteRequest } from "./apigee";

// Exports
export const request = ({
  type = "",
  url,
  data,
  method = "default",
  token,
  externalURL,
  isLogging
}) => {
  // Go to real api
  switch (method.toUpperCase()) {
    case "POST":
      return postRequest(url, data, token, externalURL, isLogging);

    case "GET":
      return getRequest(url, token, externalURL);

    case "DELETE":
      return deleteRequest(url, token);

    case "PUT":
      return putRequest(url, data, token);

    default:
      return Promise.reject(`Method not implemented yet: ${method}`);
  }
};
