// Reducers
import digitalEngagement from "./digital-engagement";
import notifications from "./notifications";
import selfcare from "./selfcare";
import watershedOverlay from "./watershed-overlay";

// Export
export default {
  digitalEngagement,
  notifications,
  selfcare,
  watershedOverlay
};
