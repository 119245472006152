// Modules
import React from "react";

// Helper
import { onEventDoNothing } from "../../utils/commons";

// Settings
const SIZE = 1;

// Component
export default React.forwardRef(
  (
    {
      index = 0,
      disabled,
      testIdPrefix = "default",
      onChange,
      onKeyUp,
      onKeyDown,
      value
    },
    ref
  ) => {
    // Functions
    const selectOnFocus = event => event.target.select();

    return (
      <div className="input-container">
        <input
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="off"
          className={disabled ? "input-disabled" : ""}
          data-index={index}
          data-test-id={`${testIdPrefix}-pin-${index}`}
          defaultValue={value}
          maxLength={SIZE}
          name={`pin-${index}`}
          onChange={onChange}
          onFocus={selectOnFocus}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onPaste={onEventDoNothing}
          ref={ref}
          size={SIZE}
          type="password"
        />
      </div>
    );
  }
);
