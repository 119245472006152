// Modules
import React from "react";

// Components
import { Link } from "@sky-uk/toolkit-react";
import FooterVersion from "components/FooterVersion/FooterVersion";

import "./footer.scss";

// Routes
import {
  ROUTE_DIGIE_IMPRINT,
  ROUTE_DIGIE_DATA_PRIVACY
} from "constants/routes";

// Settings
const digieVerificarionFooterLinks = [
  {
    name: "Impressum",
    url: ROUTE_DIGIE_IMPRINT,
    external: true
  },
  {
    name: "Datenschutz & Cookies",
    url: ROUTE_DIGIE_DATA_PRIVACY
  }
];

const Footer = ({ copyright = "Sky Deutschland Fernsehen GmbH & Co. KG" }) => {
  return (
    <footer>
      <div className="footer o-container">
        <ul className="footer__links">
          {digieVerificarionFooterLinks.map(footerItem => (
            <li className="c-text-body" key={footerItem.name} target="_blank">
              <Link
                text={footerItem.name}
                href={footerItem.url}
                external={footerItem.external}
              />
            </li>
          ))}
        </ul>
        <FooterVersion copyright={copyright} />
      </div>
    </footer>
  );
};

export default Footer;
