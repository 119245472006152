// Modules
import { EmailValidator } from "commons-validator-js";
const validateEmail = new EmailValidator();

// Helper
export const formatMessage = (errorMessage = "", isValid = false) => {
  return { isValid, errorMessage };
};

export const getSuccessMessage = () => formatMessage("", true);

// Validations
export const checkPasswordLength = val => val.length >= 8;

export const checkLowerCase = val => val.toUpperCase() !== val;

export const checkUpperCase = val => val.toLowerCase() !== val;

export const checkIfHasSymbol = val =>
  /[ !"#$%&'()*+,-./:;<=>?@[\]^_`´{|}~²³µ]/.test(val);

export const checkIfHas2Digits = val => val.replace(/[^0-9]/g, "").length >= 2;

export const checkExistence = val =>
  val !== null && val !== "" && val !== undefined;

export const checkEmailValidity = value => validateEmail.isValid(value);

export const checkLength = (value, min = 1, max = 30) =>
  value.length > min && value.length < max;

export const checkOnyNumbers = val => /^[0-9]*$/.test(val);

export const checkHasNoNumbers = val => /^([^0-9]*)$/.test(val);
