// Modules
import React, { Fragment, useState, useEffect, useRef, createRef } from "react";
import classnames from "classnames";

// Components
import PINSecret from "../pin-secret/pin-secret";
import Bubble from "../bubble/bubble";

// Validation
import validate, { isNumber, checkLength, PIN_LENGTH } from "./pin-validation";

// Utils
import { isFunction } from "../../utils/commons";

// CSS
import "./pin.scss";

// Create the number of fields
export const fields = Array.apply(null, Array(PIN_LENGTH)).map((_, idx) => idx);

// Helper
const getDataIndexFromEvent = event => parseInt(event.target.dataset.index, 10);

// Component
export default React.forwardRef(
  (
    {
      disabled,
      disableValidation,
      hideValidationBubbles,
      labelRequired = "Dies ist ein Pflichtfeld",
      name = "pinValue",
      onChange = () => {},
      onError = () => {},
      required = false,
      testIdPrefix,
      title,
      value = "",
      onlyLengthValidation
    },
    ref
  ) => {
    // States
    const [fieldValues, setFieldValues] = useState(value.split(""));
    const [validationResult, setValidationResult] = useState({});

    // References
    const secretFieldsRefs = useRef(fields.map(() => createRef()));

    // Settings
    const baseClass = "pin-component";

    // Functions
    const focusNextInput = event => {
      // Only focus next if there is a valid input
      if (!event.target.value.length) {
        return;
      }

      // Parse to string to calculate with the index
      const index = getDataIndexFromEvent(event);

      // Is not last element then focus next
      /* istanbul ignore else*/
      if (index !== PIN_LENGTH - 1) {
        const nextElement = secretFieldsRefs.current[index + 1].current;
        nextElement.focus();
      }
    };

    const changeInputValue = event => {
      let valueChangeInputValue = event.target.value;
      const isValid = isNumber(valueChangeInputValue);

      if (!isValid || disabled) {
        valueChangeInputValue = null;
        event.target.value = valueChangeInputValue;
      }

      const index = getDataIndexFromEvent(event);
      const values = [...fieldValues];
      values[index] = valueChangeInputValue;
      setFieldValues(values);
    };

    // Convert the PIN
    const getPIN = () => fieldValues.join("");

    // Hooks
    useEffect(() => {
      const pinValue = getPIN();

      // On change set value to parent ref
      if (ref) {
        ref.current.value = pinValue;
      }

      if (disableValidation) {
        onChange(pinValue, name);
        return;
      }

      // Only validate length and no deep checks
      if (onlyLengthValidation) {
        const isValidOnlyLengthValidation = checkLength(pinValue);
        onChange(pinValue, name, isValidOnlyLengthValidation);
        return;
      }

      // Validate new pin
      const { isValid, results } = validate(pinValue, onlyLengthValidation);

      // Trigger change to parent
      onChange(pinValue, name, isValid);

      // Save the result from validation
      setValidationResult(results);

      // Trigger error to parent
      /* istanbul ignore else*/
      if (!isValid && isFunction(onError)) {
        onError(results);
      }

      // eslint-disable-next-line
    }, [fieldValues]);

    useEffect(() => {
      // When field will be disabled clean up all fields
      if (disabled) {
        setFieldValues(fields.map(_ => ""));
        secretFieldsRefs.current.forEach(reference => {
          reference.current.value = "";
        });
      }
    }, [disabled]);

    // Validate on start
    useEffect(() => {
      const pinValue = getPIN();
      const { results } = validate(pinValue);
      // Save the result from validation
      setValidationResult(results);
      // eslint-disable-next-line
    }, []);

    const getBubbles = () => {
      const allFields = Object.keys(validationResult);

      if (
        onlyLengthValidation ||
        disableValidation ||
        hideValidationBubbles ||
        !allFields.length
      ) {
        return <Fragment />;
      }

      const bubbleStatus = (isCorrect, showResult) => {
        if (!showResult) {
          return "default";
        }
        if (!isCorrect) {
          return "error";
        }

        return "correct";
      };

      return allFields.map(key => {
        const { label, isCorrect, showResult } = validationResult[key];
        return (
          <Bubble
            key={key}
            name={key}
            label={label}
            status={bubbleStatus(isCorrect, showResult)}
          />
        );
      });
    };

    // CSS
    const wrapperClasses = classnames(
      {
        "is-disabled": disabled
      },
      baseClass
    );

    return (
      <div className={wrapperClasses}>
        {title && (
          <div className={`${baseClass}-title`}>
            {title}
            {required && (
              <abbr title={labelRequired} className="c-form-required">
                {" "}
                *
              </abbr>
            )}
          </div>
        )}
        <div className={baseClass}>
          <input type="hidden" name={name} id={name} ref={ref} />
          <div className={`${baseClass}-secrets`}>
            {fields.map(idx => (
              <PINSecret
                disabled={disabled}
                index={idx}
                key={`secret-pin-${idx}`}
                onChange={changeInputValue}
                onKeyUp={focusNextInput}
                ref={secretFieldsRefs.current[idx]}
                testIdPrefix={testIdPrefix}
                value={fieldValues[idx]}
              />
            ))}
          </div>
          <div className={`${baseClass}-bubbles`}>{getBubbles()}</div>
        </div>
      </div>
    );
  }
);
