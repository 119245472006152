// Modules
import axios from "axios";

// Custom hooks
import { getConfig } from "utils/hooks/config";

// Utils
import { startRequestMeasurement, endRequestMeasurement } from "utils/logger";

// Init new instance
export const apigee = axios.create();

// To measure
apigee.interceptors.request.use(request => {
  // Set the data as a header to count later the execution time
  startRequestMeasurement(request);
  return request;
});

apigee.interceptors.response.use(
  response => {
    endRequestMeasurement(response);
    return response;
  },
  error => {
    endRequestMeasurement(error, true);
    return Promise.reject(error);
  }
);

// Helper
export const getUrl = ({ url, externalURL = false, isLogging = false }) => {
  if (externalURL) {
    return url;
  }

  // Get the host based on if it is logging or api
  const { host } = getConfig(isLogging ? "logger" : "api");

  return `${host}${url}`;
};

export const getHeaders = (token, isLogging) => {
  // Get data from configuration
  const { publicKey } = getConfig("api");
  const { logKey } = getConfig("logger");

  // Default headers
  let headers = {
    "Content-Type": "application/json;charset=utf-8"
  };

  // User is logged in
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  // Logging is activated
  if (isLogging) {
    headers["X-App-Id"] = logKey;
  } else {
    headers = {
      ...headers,
      ...{
        "X-Sky-ApiKey": publicKey,
        "X-Sky-ContactChannel": "WEB",
        "X-Sky-Proposition": "SKYTICKET",
        "X-Sky-Provider": "SKY",
        "X-Sky-Territory": "DE"
      }
    };
  }

  return { headers };
};

export const postRequest = (url, data, token, externalURL, isLogging) =>
  apigee.post(
    getUrl({ url, externalURL, isLogging }),
    data,
    getHeaders(token, isLogging)
  );

export const putRequest = (url, data, token) =>
  apigee.put(getUrl({ url }), data, getHeaders(token));

export const getRequest = (url, token, externalURL) =>
  apigee.get(getUrl({ url, externalURL }), getHeaders(token));

export const deleteRequest = (url, token) =>
  apigee.delete(getUrl({ url }), getHeaders(token));
