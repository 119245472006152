// Modules
import React, { useEffect } from "react";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";

// Containers
import LoginFormContainer from "containers/login-form/login-form.container";

// Components
import BlockMessage from "components/block-message/block-message";

// Selectors
import { digiESelector } from "sagas/selectors";

// Actions
import {
  DIGIE_LOGIN_USER,
  DIGIE_LOGIN_RESET
} from "action-types/digital-engagement";

// Page
export default ({
  customerCodeErrorTitle = "Ungültiger Link",
  successTitle = "Du bist nun eingeloggt",
  customerCodeErrorMessage = `Dieser Link ist nicht mehr gültig, da du dich bereits eingeloggt hast oder dein Anliegen bereits gelöst wurde.`,
  customerCodeErrorMessageMore = "Du hast weitere Fragen? Bitte gehe zurück zum Chat und tippe dein Anliegen ein.",
  sucessMessage = "Dein Login war erfolgreich. Bitte schließe das Fenster und gehe zurück zum Chat, um deine Unterhaltung fortzuführen.",
  location,
  generalAuthErrorTitle = "Ungültiger Link",
  generalAuthErrorMessage = "Dieser Link ist nicht mehr gültig, da du dich bereits eingeloggt hast oder dein Anliegen bereits gelöst wurde.",
  generalAuthErrorMessageMore = "Du hast weitere Fragen? Bitte gehe zurück zum Chat und tippe dein Anliegen ein.",
  timeOutErrorTitle = "Session is abgelaufen",
  timeOutErrorMessage = " Dieser Link ist leider nicht mehr gültig.",
  timeOutErrorMessageMore = "Du hast noch offene Fragen? Bitte gehe zurück zum Chat und tippe dein Anliegen ein."
}) => {
  // Init
  const dispatch = useDispatch();

  // Get email verification token from url
  const { deviceCode } = queryString.parse(location.search);

  // Store
  const { error, success } = useSelector(digiESelector);

  // Function
  const onLogin = data => {
    dispatch({
      type: DIGIE_LOGIN_USER,
      payload: {
        ...data,
        deviceCode
      }
    });
  };

  // Hooks
  useEffect(() => {
    // On start reset the whole from with errors
    dispatch({ type: DIGIE_LOGIN_RESET });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`o-container`}>
      <div className="o-layout o-layout--center">
        {success && (
          <div className="o-layout__item u-text-center u-width-1/1 u-width-6/12@medium u-width-8/12@large">
            <BlockMessage
              title={successTitle}
              message={sucessMessage}
              state="success"
            />
          </div>
        )}

        {error.digiReturnCode === 301 && !success && (
          <div className="o-layout__item u-width-1/1 u-width-6/12@medium u-width-8/12@large">
            <BlockMessage
              title={timeOutErrorTitle}
              message={timeOutErrorMessage}
              messageDetail={timeOutErrorMessageMore}
            />
          </div>
        )}

        {(!deviceCode || error.deviceCode) && !success && (
          <div className="o-layout__item u-width-1/1 u-width-6/12@medium u-width-8/12@large">
            <BlockMessage
              title={
                error.digiReturnCode === 401
                  ? customerCodeErrorTitle
                  : generalAuthErrorTitle
              }
              message={
                error.digiReturnCode === 401
                  ? customerCodeErrorMessage
                  : generalAuthErrorMessage
              }
              messageDetail={
                error.digiReturnCode === 401
                  ? customerCodeErrorMessageMore
                  : generalAuthErrorMessageMore
              }
            />
          </div>
        )}

        {deviceCode && !error.deviceCode && !success && (
          <div className="o-layout__item u-width-1/1 u-width-6/12@medium u-width-4/12@large">
            <LoginFormContainer
              onLogin={onLogin}
              errorMessage={error ? error.message : null}
            />
          </div>
        )}
      </div>
    </div>
  );
};
