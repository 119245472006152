export const handleChangeEmailHelper = (
  value,
  name,
  isDigiE,
  checkCustomerNumber,
  validateLogin,
  setValidationErrors,
  setFieldData
) => {
  const { isValid, errorMessage } = isDigiE
    ? checkCustomerNumber(name, value)
    : validateLogin(name, value);

  setValidationErrors(errorMessage);
  setFieldData(name, value, isValid);
};

export const handleSubmitHelper = event => {
  /* istanbul ignore else*/
  if (event) event.preventDefault();
};

export const createCorrectObject = (
  formValues,
  isDigiE,
  values,
  LOGIN_FIELD_KEEP_LOGIN
) => {
  Object.keys(formValues).forEach(field => {
    if (field !== LOGIN_FIELD_KEEP_LOGIN) {
      values[field] = formValues[field].value;
    }
  });
};
