// Modules
import React from "react";
import classnames from "classnames";

// Icons
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";

// CSS
import "./bubble.scss";

// Component
export default ({ label, status = "", name = "default", isCorrect }) => {
  // CSS
  const baseClass = "bubble";
  const classes = classnames(
    {
      correct: isCorrect || status === "correct",
      error: status === "error"
    },
    baseClass
  );

  // Icon depending on status
  let icon;
  switch (status) {
    case "correct":
      icon = (
        <TickIcon
          className="icon-tick"
          title="checked"
          width="15"
          height="20"
          viewBox="0 0 20 10"
        />
      );
      break;

    case "error":
      icon = (
        <CrossIcon className="icon-cross" title="checked" viewBox="0 0 25 25" />
      );
      break;

    default:
      icon = "";
      break;
  }

  return (
    <div className={classes} data-test-id={`bubble-${name}`}>
      <span className="c-text-smallprint u-text-bold">
        {icon}
        {label}
      </span>
    </div>
  );
};
