// Utils
import {
  formatMessage,
  getSuccessMessage,
  checkEmailValidity,
  checkExistence,
  checkOnyNumbers
} from "utils/validationHelper";

// Constants
import { DIGIE_ERROR_EMAIL } from "constants/digie-verification";

// Settings
export const CUSTOMER_NUMBER_MAX_LENGTH = 10;

// Validations
// This is a different check to registration
const checkEmail = value => {
  if (!checkExistence(value) || !checkEmailValidity(value)) {
    return formatMessage(DIGIE_ERROR_EMAIL);
  }
  return getSuccessMessage();
};

// Handle registration validations: The _ is because this will also be used
// In the defeault validation check for inputs
export default (_, value) => {
  if (checkOnyNumbers(value) && value.length === CUSTOMER_NUMBER_MAX_LENGTH) {
    return getSuccessMessage();
  } else {
    return checkEmail(value);
  }
};
