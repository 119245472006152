// Modules
import { all } from "redux-saga/effects";

// Sagas
import { watchDigitalEngagement } from "./digital-engagement.saga";
import { watchLogger } from "store/sagas/logger";

export default function* rootSaga() {
  try {
    yield all([watchDigitalEngagement(), watchLogger()]);
  } catch (error) {
    // error management depending on production or dev environments
    console.log("ERROR SAGAS MAIN", error);
  }
}
