// Modules
import { put, takeEvery, call } from "redux-saga/effects";

// Store
import { LOGGER_WRITE_LOG } from "store/actions/logger";
import { errorHandler } from "store/sagas/global";

// Constants
import { LOGGER_TYPE_INFO } from "constants/logger";

// Utils
import { formatErrorPayload } from "utils/logger";

// Custom hooks
import { getConfig } from "utils/hooks/config";

// Services
import { writeLogs } from "services/api";

// Generator
// This function can be used inside the sagas
export function* errorLogger(errorData) {
  // Dispatch to store
  yield put(formatErrorPayload(errorData));
}

export function* sendLog({ payload }) {
  try {
    // Logger config
    const { isActive } = getConfig("logger");

    // Do not send any logs when it is not explicit activated
    if (!isActive) {
      return;
    }

    // Get the data
    const { severity = LOGGER_TYPE_INFO, message = "", details = {} } = payload;

    // Call the API
    yield call(writeLogs, { severity, payload: { message, details } });
  } catch (error) {
    // Do no re trigger itself
    yield call(errorHandler, error, true);
  }
}

// Watcher
export function* watchLogger() {
  yield takeEvery(LOGGER_WRITE_LOG, sendLog);
}
