// Modules
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// Pages
import AGBPage from "pages/legal/agb.page";
import DataPrivacyPage from "pages/legal/dataPrivacy.page";
import DigieVerificationPage from "pages/digie-verification/digie-verification.page";

// Routes
import {
  ROUTE_DIGIE_DATA_AGB,
  ROUTE_DIGIE_DATA_PRIVACY,
  ROUTE_DIGIE_VERIFICATION
} from "constants/routes";

// Router
export default () => {
  return (
    <Switch>
      {/* Digital engagement routes */}
      <Route path={ROUTE_DIGIE_DATA_PRIVACY} component={DataPrivacyPage} />
      <Route path={ROUTE_DIGIE_DATA_AGB} component={AGBPage} />
      <Route
        path={ROUTE_DIGIE_VERIFICATION}
        component={DigieVerificationPage}
      />

      <Redirect to={ROUTE_DIGIE_VERIFICATION} />
    </Switch>
  );
};
