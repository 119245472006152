// Modules
import React from "react";

// Components
import ErrorMessage from "components/error-message/error-message";

// Utils
import { getResetLink } from "utils/auth";

// Constants
import {
  DIGIE_LOGIN_ERROR_CUSTOMER_LOCKED,
  DIGIE_LOGIN_RESET_LINK_TEXT
} from "constants/digie-verification";

// Component
const DigieMessagesWrapper = () => {
  return (
    <div>
      <div className="u-text-center">
        <ErrorMessage
          message={DIGIE_LOGIN_ERROR_CUSTOMER_LOCKED}
          backgroundColor="red"
          linkText={DIGIE_LOGIN_RESET_LINK_TEXT}
          linkTarget={getResetLink()}
        />
      </div>
    </div>
  );
};
export { DigieMessagesWrapper };
