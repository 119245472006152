// Try to get return_code from api and also status and return this
// This will save us to write every check again
export const getErrorCodes = error => {
  let returnCode = 500;
  let statusCode = 500;
  let returnMessage = "";

  if (error && error.response) {
    const { data, status } = error.response;

    // Check if status is valid
    if (status) {
      statusCode = status;
    }

    // Try to get the return_code from API response
    if (data && data.return_code) {
      returnCode = parseInt(data.return_code, 10);
    }

    // Try to get the return_code from API response
    if (data && data.return_description) {
      returnMessage = data.return_description;
    }
  }

  return { returnCode, statusCode, returnMessage };
};
