import sanitizeHtml from "sanitize-html";

// Routes
import { ROUTE_DIGIE_BASE } from "constants/routes";

export const checkDigiE = () =>
  window.location.pathname.startsWith(ROUTE_DIGIE_BASE);

export const clean = dirty =>
  sanitizeHtml(dirty, {
    allowedTags: ["a", "p", "br"],
    allowedAttributes: {
      a: ["href", "target"]
    }
  });
