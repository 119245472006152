// Constants
import {
  PIN_VALIDATION_ERROR_NOT_EQUALS,
  PIN_VALIDATION_BUBBLE_EQUAL,
  PIN_VALIDATION_BUBBLE_SEQUENCE,
  PIN_VALIDATION_ERROR_IS_SEQUENCE
} from "../../constants/pin";

// Settings
export const PIN_LENGTH = 4;

// Regex expressions
const equalsExp = /\b(\d)\1+\b/i;
const isNumberRegex = /^[0-9]*$/;

// Helper
export const isNumber = value => isNumberRegex.test(value);

const checkSequence = value => {
  const numbers = "0123456789";
  const numbersRev = "9876543210";
  return !(numbers.indexOf(value) === -1 && numbersRev.indexOf(value) === -1);
};

// Different validations
const equalsValidation = value => {
  // Defaults
  const pinLength = value.length;
  const isEquals = equalsExp.test(value);
  let isCorrect = !isEquals;

  // If user enter more than one value and these are different and not equal
  if (pinLength > 1 && !isEquals) {
    isCorrect = true;
  } else if (pinLength < 2) {
    isCorrect = false;
  }

  return {
    isCorrect,
    label: PIN_VALIDATION_BUBBLE_EQUAL,
    showResult: isCorrect || pinLength === PIN_LENGTH ? true : false
  };
};

const sequenceValidation = value => {
  // Defaults
  const pinLength = value.length;
  const isSequence = checkSequence(value);
  let isCorrect = !isSequence;

  // If user enter more than one value and these are different and not equal
  if (pinLength > 1 && !isSequence) {
    isCorrect = true;
  }

  return {
    isCorrect,
    label: PIN_VALIDATION_BUBBLE_SEQUENCE,
    showResult: isCorrect || pinLength === PIN_LENGTH ? true : false
  };
};

export const checkLength = value => value.length === PIN_LENGTH;

// Function
export default value => {
  // Defaults
  const results = {};
  let isValid = false;

  results[PIN_VALIDATION_ERROR_NOT_EQUALS] = equalsValidation(value);
  results[PIN_VALIDATION_ERROR_IS_SEQUENCE] = sequenceValidation(value);

  // Check is valid
  if (value.length === PIN_LENGTH) {
    let allFieldsValid = true;
    [PIN_VALIDATION_ERROR_NOT_EQUALS, PIN_VALIDATION_ERROR_IS_SEQUENCE].forEach(
      field => {
        const { isCorrect } = results[field];
        if (!isCorrect) {
          allFieldsValid = false;
        }
      }
    );
    isValid = allFieldsValid;
  }

  return {
    isValid,
    results
  };
};
