// Modules
import React from "react";

// Component
export default () => {
  return (
    <div>
      <h1 className="c-heading-bravo">AGBs</h1>
      <p className="c-text-body">Legalese.....</p>
      <p className="coming-soon">Coming soon...</p>
    </div>
  );
};
