// Modules
import React from "react";
import { NavLink } from "react-router-dom";

// External Icons
import { Icon } from "@sky-uk/toolkit-react";
import linearArrowRight from "@sky-uk/skycons/dist/linearArrowRight";

// css
import "./main-menu-item.scss";

const baseClass = "main-item";

export default ({ label, url = "", setIsMobileMenuOpen }) => {
  return (
    <li className={`${baseClass}`} key={label}>
      <NavLink
        className="main-link"
        to={url}
        data-test-id="main-menu-item"
        onClick={() => {
          setIsMobileMenuOpen(false);
        }}
      >
        {label}
        <Icon
          className={`${baseClass}__arrow_right`}
          svgSource={linearArrowRight}
        />
      </NavLink>
    </li>
  );
};
