// Actions
import {
  SELFCARE_RESET_PASSWORD_FAILED,
  SELFCARE_RESET_PASSWORD_SUCCESS,
  SELFCARE_VERIFICATION_PASSWORD_SAVE_FAILED,
  SELFCARE_VERIFICATION_PASSWORD_SAVE_WRONG_TOKEN,
  SELFCARE_RESET,
  SELFCARE_SET_PASSWORD_ERROR
} from "action-types/selfcare";

// Defaults
export const initialState = {
  error: false,
  success: false,
  errorSave: false,
  invalidToken: false,
  errorSetPassword: false,
  errorSetPasswordMessage: ""
};

// Reducer
export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
    case SELFCARE_RESET:
      return initialState;

    case SELFCARE_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        invalidToken: false
      };

    case SELFCARE_RESET_PASSWORD_FAILED:
      return {
        ...state,
        success: false,
        error: true,
        invalidToken: false
      };

    case SELFCARE_VERIFICATION_PASSWORD_SAVE_FAILED:
      return {
        ...state,
        errorSave: true,
        invalidToken: false
      };

    case SELFCARE_VERIFICATION_PASSWORD_SAVE_WRONG_TOKEN:
      return {
        ...state,
        invalidToken: true
      };

    case SELFCARE_SET_PASSWORD_ERROR:
      // Get data from payload
      const { errorSetPasswordMessage } = payload;

      return {
        ...state,
        errorSetPassword: true,
        errorSetPasswordMessage
      };

    default:
      return state;
  }
};
