// Modules
import React from "react";
import { Button } from "@sky-uk/toolkit-react";

// Icons
import { ReactComponent as AlertIcon } from "../../assets/icons/linear-alert.svg";
import { ReactComponent as SuccessCheckmark } from "assets/icons/success-checkmark.svg";

// CSS
import "./block-message.scss";

// Component
export default ({
  title,
  message,
  messageDetail = "",
  buttonText,
  onClickHandler,
  state = "error"
}) => {
  // CSS
  const baseClass = "block-message";

  return (
    <div className={`${baseClass} u-text-center`} data-test-id="block-message">
      {state === "error" && (
        <AlertIcon
          className="alert-icon"
          title="alert-icon"
          data-test-id="alert-linear-icon"
        />
      )}

      {state === "success" && <SuccessCheckmark className="icon-checkmark" />}

      <h2 className={`${baseClass}-title c-heading-charlie`}>{title}</h2>
      <div>
        <p className="c-text-body">{message}</p>
      </div>
      <p className="c-text-body">{messageDetail}</p>
      {buttonText && (
        <Button
          data-test-id="redirect-button"
          className="c-btn c-btn--primary"
          text={buttonText}
          onClick={onClickHandler}
        />
      )}
    </div>
  );
};
