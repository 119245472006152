// Modules
import React from "react";

import { clean } from "utils/digital-engagement";

// Icons
import { ReactComponent as AlertIcon } from "assets/icons/alert.svg";

// CSS
import "./error-message.scss";

// Component
export default ({
  backgroundColor = "white",
  iconWhite,
  message = "Es ist ein Fehler aufgetreten",
  imageTitle,
  linkText,
  linkTarget
}) => {
  return (
    <div
      className={`error-message-${backgroundColor}-bg c-text-body`}
      data-test-id="error-message"
    >
      <div className="message">
        <AlertIcon
          className={`icon ${iconWhite ? "icon-white" : "icon-alert"}`}
          title={imageTitle}
        />
        <span dangerouslySetInnerHTML={{ __html: clean(message) }}></span>
      </div>
    </div>
  );
};
