import React from "react";

import { clean } from "utils/digital-engagement";

import classnames from "classnames";

import { applyDataAttributes } from "@sky-uk/toolkit-react";
import { buildAriaDescribedBy, buildControlMessageId } from "../utils";

export const Input = ({
  id,
  type,
  value,
  readOnly,
  required,
  maxLength,
  placeholder,
  labelText,
  message,
  errorMessage,
  cssClassName,
  dataAttributes,
  stateModifier,
  ariaDescribedBy,
  onChange,
  onKeyPress,
  onBlur,
  onFocus,
  min,
  max,
  inputRef
}) => {
  const modifierClasses = {};

  modifierClasses[`is-${stateModifier}`] = typeof stateModifier !== "undefined";

  const componentClassNames = classnames(modifierClasses, cssClassName);

  const requiredNotice = (
    <abbr title="This field is required" className="c-form-required">
      {" "}
      *
    </abbr>
  );

  const label = (
    <label className="c-form-label" data-test-id="label-message" htmlFor={id}>
      {labelText}
      {required && requiredNotice}
    </label>
  );

  const messageId = buildControlMessageId(id);
  const errorId = buildControlMessageId(id, "error-message");
  const inputProps = {
    id,
    type,
    value,
    readOnly,
    required,
    placeholder,
    name: id,
    className: `c-form-${type === "date" ? type : "input"}`,
    disabled: stateModifier === "disabled",
    "aria-describedby": buildAriaDescribedBy(
      [messageId, errorId].filter(Boolean).join(" "),
      ariaDescribedBy
    ),
    onChange: event => onChange(event.target.value, event),
    onKeyPress,
    onBlur,
    onFocus,
    min,
    max
  };

  return (
    <div
      className={componentClassNames}
      {...applyDataAttributes("toolkit-input", dataAttributes)}
    >
      {labelText && label}
      <input ref={inputRef} {...inputProps} />
      {stateModifier === "error" && message && (
        <span
          id={errorId}
          className="c-control-message is-error c-text-smallprint u-margin-bottom-none"
          dangerouslySetInnerHTML={{ __html: clean(message) }}
        ></span>
      )}
    </div>
  );
};

Input.defaultProps = {
  cssClassName: [],
  type: "text",
  onChange: () => {},
  onKeyPress: () => {},
  onBlur: () => {},
  onFocus: () => {}
};

export default Input;
