// Store
import { LOGGER_WRITE_LOG } from "store/actions/logger";

// Custom hooks
import { getConfig } from "utils/hooks/config";

// Store
import { store } from "store";

// Constants
import {
  LOGGER_TYPE_ERROR,
  LOGGER_TYPE_INFO,
  LOGGER_PREFIX_MEASURE,
  LOGGER_MEASURE_TYPE_REQUEST,
  LOGGER_MEASURE_TYPE_RENDER,
  LOGGER_UNTITLED_ERROR
} from "constants/logger";
import { API_LOGS } from "constants/api";

// Utils
import { getCurrentTimeStamp } from "utils/date";
import { createHashCode } from "utils/commons";

// Bring the log into the correct format
export const formatLog = ({
  details = {},
  message,
  severity = LOGGER_TYPE_INFO
}) => {
  return {
    type: LOGGER_WRITE_LOG,
    payload: {
      message,
      severity,
      details
    }
  };
};

// Format the error correct for the GCP api
export const formatErrorPayload = ({ message, error = {} } = {}) => {
  // Allow user to pass custom message
  let errorMessage = message || error.message || LOGGER_UNTITLED_ERROR;

  return formatLog({
    message: errorMessage,
    severity: LOGGER_TYPE_ERROR,
    details: error.stack
  });
};

/*

  Request measurements

  All request called by axios are handled with these functionality to measure
  the request time and send them back to GCP as a log.

*/

// List of all running requests
const runningMeasurements = {};

// Create unique id to fill the running measurement object based on url and method
export const createMeasurementId = (url, method) =>
  createHashCode(`${url}-${method}`);

// Create entry in shared object and save the starting time of the request
export const startRequestMeasurement = request => {
  // Extract the data
  const { method, url } = request;
  const requestId = createMeasurementId(url, method);

  // Add measurement to handle it later
  runningMeasurements[requestId] = {
    method,
    url,
    startTime: getCurrentTimeStamp()
  };
};

// Called by axios measurement for success and failure response.
// This will calculate the time and also the state and write the correct logs
// with a special prefix, e.g. "[MEASURE] request: /purchase/v1/session"
export const endRequestMeasurement = (response, hasError = false) => {
  // Get the host based on if it is logging or api
  const { host } = getConfig("api");
  const { host: loggerHost } = getConfig("logger");

  // List with all ful urls which are excluded from measurement
  const excludedPathsFromMeasure = [`${loggerHost}${API_LOGS}`];

  // Extract the data
  const { method, url } = response.config;
  const requestId = createMeasurementId(url, method);

  // Get starttime
  const currentRequest = runningMeasurements[requestId] || {};

  // Calculate the time
  const start = currentRequest.startTime;
  const endTime = getCurrentTimeStamp();
  const executionTimeInMs = endTime - start;

  // Extract the url to check
  const urlPath = url.replace(host, "");

  // Ignore logs for all items from the list
  if (!excludedPathsFromMeasure.includes(url)) {
    const payload = formatMeasurePayload(
      LOGGER_MEASURE_TYPE_REQUEST,
      urlPath,
      {
        url,
        executionTimeInMs,
        successful: !hasError,
        method
      },
      hasError
    );
    store.dispatch(payload);
  }
};

// Bring the measure payload in the right format, e.g. "[MEASURE] request: /purchase/v1/session"
export const formatMeasurePayload = (
  measureType = "",
  measureTitle = "",
  details = {},
  hasError = false
) => {
  const severity = hasError ? LOGGER_TYPE_ERROR : LOGGER_TYPE_INFO;
  const message = `[${LOGGER_PREFIX_MEASURE}] ${measureType}: ${
    details.method ? `${details.method.toUpperCase()} ` : ""
  }${measureTitle}`;
  return formatLog({ severity, message, details });
};

// Create independent measurement and
export class Measurement {
  startTime = 0;
  executionTimeInMs = 0;

  constructor(title) {
    this.title = title;
  }

  start() {
    this.startTime = getCurrentTimeStamp();
  }

  reset() {
    this.startTime = 0;
    this.executionTimeInMs = 0;
  }

  end(preventLog = false) {
    // Calculate the execution time in milliseconds
    const endTime = getCurrentTimeStamp();
    this.executionTimeInMs = endTime - this.startTime;

    // Check to write logs
    if (!preventLog) {
      this.writeLog();
    }
  }

  writeLog() {
    const payload = formatMeasurePayload(
      LOGGER_MEASURE_TYPE_RENDER,
      this.title,
      {
        executionTimeInMs: this.executionTimeInMs
      }
    );
    store.dispatch(payload);

    // Reset all measures
    this.reset();
  }

  getExecutionTime() {
    return this.executionTimeInMs;
  }
}
