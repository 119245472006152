// Defaults
import { API_VALIDATION_MPP } from "./defaults";

// Codes
export const PIN_VALIDATION_ERROR_IS_SEQUENCE = "is-sequence";
export const PIN_VALIDATION_ERROR_NOT_EQUALS = "not-equals";
export const PIN_VALIDATION_ERROR_NOT_THE_SAME = "not-the-same";
export const PIN_VALIDATION_ERROR_LETTERS = "is-letter";
export const PIN_VALIDATION_ERROR_API = "api-error";
export const PIN_VALIDATION_ERROR_TOKEN = "token-error";
export const PIN_VALIDATION_ERROR_REPEAT = "repeat-error";
export const PIN_VALIDATION_ERROR_INCOMPLETED = "incompleted";
export const PIN_VALIDATION_ERROR_WRONG = "wrong-pin";
export const PIN_VALIDATION_ERROR_LENGTH = "error-length";
export const PIN_VALIDATION_SUCCESS = "success";

// Bubbles
export const PIN_VALIDATION_BUBBLE_EQUAL = "Unterschiedliche Ziffern";
export const PIN_VALIDATION_BUBBLE_SEQUENCE = "Keine Ziffernfolge";

// Texts
export const PIN_TEXT_ERROR_IS_SEQUENCE =
  "Deine Jugendschutz PIN sollte keine Zahlenfolge enthalten";
export const PIN_TEXT_ERROR_NOT_EQUALS =
  "Deine Jugendschutz PIN sollte aus verschiedenen Zahlen bestehen";
export const PIN_TEXT_ERROR_NOT_THE_SAME =
  "Die Eingabe der PINs stimmen nicht überein.";
export const PIN_TEXT_ERROR_LETTERS =
  "Deine Jugendschutz PIN sollte nur aus Zahlen bestehen";
export const PIN_TEXT_ERROR_API =
  "Bitte überprüfe deine Eingaben und versuche es erneut.";
export const PIN_TEXT_ERROR_TOKEN =
  "Dein Token ist leider abgelaufen. Bitte fordere diesen erneut an.";
export const PIN_TEXT_ERROR_REPEAT =
  "Bitte wiederhole deine gewünschte Jugendschutz-PIN";
export const PIN_TEXT_ERROR_WRONG = API_VALIDATION_MPP;

// Settings
export const PIN_LENGTH = 4;
