// Utils
import {
  formatMessage,
  getSuccessMessage,
  checkEmailValidity,
  checkExistence
} from "utils/validationHelper";

// Constants
import { REG_ERROR_EMAIL } from "constants/registration";

// Validations
// This is a different check to registration
const checkEmail = value => {
  if (!checkExistence(value) || !checkEmailValidity(value)) {
    return formatMessage(REG_ERROR_EMAIL);
  }
  return getSuccessMessage();
};

// Handle registration validations
// NOTE: At the moment this is only used for email so we do not need any other
// check here
export default (name, value) => checkEmail(value);
