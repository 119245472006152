import React from "react";

import "./backDrops.scss";

export default ({ show, children, backgroundStyle }) => {
  const baseClass = "backDrop";

  return show ? (
    <div
      data-test-id={`${baseClass}`}
      className={`backDrops ${backgroundStyle}`}
    >
      {children}
    </div>
  ) : null;
};
