let buildControlMessageId = function buildControlMessageId(id) {
  let type =
    arguments.length > 1 && arguments[1] !== undefined
      ? arguments[1]
      : "message";
  return id ? "".concat(id, "-").concat(type) : null;
};

let buildAriaDescribedBy = function buildAriaDescribedBy(
  messageId,
  customAriaId
) {
  return [messageId, customAriaId].filter(Boolean).join(" ") || null;
};

export { buildAriaDescribedBy, buildControlMessageId };
