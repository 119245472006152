/* eslint-disable jsx-a11y/anchor-is-valid */
// Modules
import React, { useEffect, useState } from "react";

// Custom hooks
import { getConfig } from "utils/hooks/config";

// Components
import { ResponsiveImage, Icon } from "@sky-uk/toolkit-react";
import MobileMenu from "containers/header/mobile-menu/mobile-menu";
import SkyLogo from "assets/sky-logo.png";

// External Icons
import linearList from "@sky-uk/skycons/dist/linearList";
import linearCross from "@sky-uk/skycons/dist/linearCross";

// Routes externals
import { SKY_GERMANY } from "constants/externalURLs";

// CSS
import "./header.scss";

const MAX_MOBILE_WIDTH = 980;

export default ({
  userProfile,
  digieVerification,
  labels = {
    helpTitle: "Hilfe"
  }
}) => {
  // States
  const [isOpened, setIsOpened] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  // Choose menu to display based on screen size
  useEffect(() => {
    const onResize = () => {
      return setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    };

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  //  Menu  on click handler
  const menuItemClickHandler = evt => {
    evt.preventDefault();
    setIsOpened(!isOpened);
    return false;
  };

  const { url } = getConfig("helpcenter");

  const baseClass = "header";
  return (
    <div className={`main-container`}>
      <div className={`o-container base-container`}>
        <div className={`${baseClass}`}>
          {dimensions.width <= MAX_MOBILE_WIDTH && !digieVerification ? (
            <div
              className={`${baseClass}__burger-menu-wrapper`}
              onClick={() => {
                setIsMobileMenuOpen(!isMobileMenuOpen);
              }}
            >
              <Icon
                className={`${baseClass}__burger-menu`}
                svgSource={linearList}
              />
            </div>
          ) : (
            ""
          )}
          <div
            className={`${baseClass}__logo-wrapper `}
            data-test-id="header-headline"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <a target="_blank" rel="noopener noreferrer" href={SKY_GERMANY}>
              <ResponsiveImage
                src={SkyLogo}
                cssClassName={`${baseClass}__logo`}
                ariaHidden
              />
            </a>
          </div>

          {dimensions.width >= MAX_MOBILE_WIDTH && !digieVerification ? (
            <div className={`${baseClass}__main-menu o-layout__item`}>
              <a
                className="main-menu-link external-link"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {labels.helpTitle}
              </a>
            </div>
          ) : (
            ""
          )}

          {dimensions.width < MAX_MOBILE_WIDTH && isMobileMenuOpen && (
            <div
              onClick={() => {
                setIsMobileMenuOpen(!isMobileMenuOpen);
              }}
              className="close-icon-burger"
            >
              <Icon
                className={`${baseClass}__burger-menu`}
                svgSource={linearCross}
              />
            </div>
          )}
        </div>
      </div>
      {dimensions.width < MAX_MOBILE_WIDTH && isMobileMenuOpen ? (
        <MobileMenu
          menuItemClickHandler={menuItemClickHandler}
          digieVerification={digieVerification}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        />
      ) : (
        ""
      )}
    </div>
  );
};
