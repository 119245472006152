import React, { useEffect, useState } from "react";

// CSS
import "./watershed-overlay.scss";

// Images
import { ReactComponent as Close } from "assets/icons/close.svg";
import { ReactComponent as SuccessCheckmark } from "assets/icons/success-checkmark.svg";

// Store helper
import closeOverlay from "./close-overlay";

import { createMarkup } from "../../utils/commons";

export default ({ title = "Title", content, button, center }) => {
  // Check if the browser back button is clicked, then close the modal overlay
  window.onpopstate = closeOverlay;

  // CSS
  const baseClass = "watershed-overlay";

  const [verticalAlign, setVerticalAlign] = useState("align-top");

  const verticalCenter = () => {
    const containerHeight = document.getElementById(
      "watershed-overlay-container"
    ).offsetHeight;
    const screenHeight = document.documentElement.clientHeight;

    if (containerHeight <= screenHeight - 50) {
      return "align-center";
    }
    return "align-top";
  };

  useEffect(() => {
    setVerticalAlign(verticalCenter());
  }, []);

  return (
    <div className="scroll">
      <div className={`${baseClass} c-text-body`}>
        <div className={`${verticalAlign} o-layout`}>
          <div
            className={`${baseClass}--container o-layout__item  u-width-12/12 u-width-8/12@medium`}
            id="watershed-overlay-container"
          >
            <div
              className={`${baseClass}--header`}
              onClick={closeOverlay}
              data-test-id="overlay-close-icon"
            >
              <Close className={`${baseClass}--header--icon`} />
            </div>
            <div className={`${center && "u-text-center"} o-container`}>
              <div className={`${baseClass}--content`}>
                {button ? (
                  <SuccessCheckmark className={`success-icon`} />
                ) : (
                  <h3
                    className={`${baseClass}--title u-text-center c-heading-charlie`}
                  >
                    {title}
                  </h3>
                )}
                <div
                  className="content"
                  dangerouslySetInnerHTML={createMarkup(content)}
                ></div>
              </div>
              {button && (
                <button className="c-btn c-btn--primary" onClick={closeOverlay}>
                  OK
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
