// Actions
import {
  OVERLAY_MPP_MORE_INFO,
  OVERLAY_CUSTOMER_NUMBER_INFO,
  OVERLAY_EU_PORTABILITY_INFO,
  OVERLAY_CLOSE
} from "action-types/watershed-overlay";

// Constants
import {
  MPP_INFO_TITLE,
  MPP_INFO_CONTENT,
  CUSTOMER_NUMBER_TITLE,
  CUSTOMER_NUMBER_CONTENT,
  EU_PORTABILITY_INFO_CONTENT,
  EU_PORTABILITY_INFO_TITLE
} from "constants/watershed-overlay";

// Defaults
export const initialState = {
  overlayStatus: false,
  overlayTitle: null,
  overlayText: null,
  overlayButton: false,
  centerAlign: false,
  typeModalMessage: null
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case OVERLAY_CLOSE:
      return {
        ...state,
        overlayStatus: false,
        overlayTitle: null,
        overlayText: null
      };

    case OVERLAY_MPP_MORE_INFO:
      return {
        ...state,
        overlayStatus: true,
        overlayText: MPP_INFO_CONTENT,
        overlayTitle: MPP_INFO_TITLE,
        overlayButton: false,
        centerAlign: false
      };

    case OVERLAY_CUSTOMER_NUMBER_INFO:
      return {
        ...state,
        overlayStatus: true,
        overlayText: CUSTOMER_NUMBER_CONTENT,
        overlayTitle: CUSTOMER_NUMBER_TITLE,
        overlayButton: false,
        centerAlign: false
      };

    case OVERLAY_EU_PORTABILITY_INFO:
      return {
        ...state,
        overlayStatus: true,
        overlayText: EU_PORTABILITY_INFO_CONTENT,
        overlayTitle: EU_PORTABILITY_INFO_TITLE,
        overlayButton: false,
        centerAlign: false
      };

    default:
      return state;
  }
};
