// Errors
export const REG_ERROR_FIRSTNAME_LENGTH =
  "Dein Vorname sollte zwischen 2 und 30 Zeichen lang sein";
export const REG_ERROR_LASTNAME_LENGTH =
  "Dein Nachname sollte zwischen 2 und 30 Zeichen lang sein";
export const REG_ERROR_SPECIAL_CHARACTER =
  "Bitte verwende keine Zahlen oder Sonderzeichen";
export const REG_ERROR_EMAIL_LENGTH =
  "Deine E-Mail Adresse sollte zwischen 6 und 30 Zeichen lang sein.";
export const REG_ERROR_EMAIL = "Bitte gib eine gültige E-Mail Adresse ein";
export const REG_ERROR_EMAIL_ALREADY_EXISTS =
  "Es existiert bereits ein Account mit dieser E-Mail Adresse.";
export const REG_ERROR_EMAIL_ALREADY_EXISTS_LINK = "Melde dich jetzt an.";
export const REG_ERROR_API =
  "Leider ist ein Fehler aufgetreten. Bitte versuche es erneut.";

// Fieldnames
export const REG_FIELD_CCK = "cck";
export const REG_FIELD_EMAIL = "email";
export const REG_FIELD_FIRSTNAME = "first-name";
export const REG_FIELD_LASTNAME = "last-name";
export const REG_FIELD_MARKETING_PERMISSION = "marketing-permission";
export const REG_FIELD_PASSWORD = "password";
export const REG_FIELD_SKY_PIN = "skyPin";
