// Modules
import { call, put, takeEvery } from "redux-saga/effects";

// Store
import {
  DIGIE_LOGIN_SET_ERROR,
  DIGIE_LOGIN_SUCCESS,
  DIGIE_LOGIN_USER
} from "action-types/digital-engagement";
import { errorHandler } from "store/sagas/global";

// Services
import { digiELogin } from "services/api";

// Helper
import { getErrorCodes } from "utils/api";

// Constants
import { LOGIN_ERROR_API_ERROR } from "constants/login";
import {
  DIGIE_CUSTOMER_TWO_ATEMPTS_REMAINING_FIRST_PART,
  DIGIE_CUSTOMER_TWO_ATEMPTS_REMAINING_SECOND_PART,
  DIGIE_LOGIN_CUSTOMER_NOT_FOUND,
  DIGIE_LOGIN_ERROR_CUSTOMER_LAST_TRY_FIRST_PART,
  DIGIE_LOGIN_ERROR_CUSTOMER_LAST_TRY_SECOND_PART,
  DIGIE_LOGIN_ERROR_CUSTOMER_LOCKED,
  DIGIE_ERROR_SUSPENDED_CUSTOMER
} from "constants/digie-verification";

// Generators
export function* handleLogin({ payload }) {
  try {
    yield call(digiELogin, payload);
    yield put({ type: DIGIE_LOGIN_SUCCESS });
  } catch (error) {
    // First log the error
    yield call(errorHandler, error);

    // Statuscodes:
    // 401: Wrong customer code
    // Any other: Global error -> Error page will be shown
    const { statusCode, returnCode } = getErrorCodes(error);

    if (statusCode === 401 || statusCode === 400) {
      yield put({
        type: DIGIE_LOGIN_SET_ERROR,
        payload: {
          deviceCode: true,
          digiReturnCode: returnCode
        }
      });
      return;
    }

    let apiError = LOGIN_ERROR_API_ERROR;

    if (statusCode === 404 && returnCode === 207) {
      apiError = DIGIE_LOGIN_CUSTOMER_NOT_FOUND;
    }

    // Customer is locked
    if (statusCode === 403 && returnCode === 202) {
      apiError = DIGIE_LOGIN_ERROR_CUSTOMER_LOCKED;
    }

    // Customer is suspended
    if (statusCode === 403 && returnCode === 226) {
      apiError = DIGIE_ERROR_SUSPENDED_CUSTOMER;
    }

    // Check wrong login and last try
    else if (
      statusCode === 406 &&
      returnCode === 201 &&
      error.response.data.availableAttempts === 1
    ) {
      apiError = `${DIGIE_LOGIN_ERROR_CUSTOMER_LAST_TRY_FIRST_PART} ${DIGIE_LOGIN_ERROR_CUSTOMER_LAST_TRY_SECOND_PART}`;
    } else if (
      statusCode === 406 &&
      returnCode === 201 &&
      error.response.data.availableAttempts === 2
    ) {
      apiError = `${DIGIE_CUSTOMER_TWO_ATEMPTS_REMAINING_FIRST_PART} ${DIGIE_CUSTOMER_TWO_ATEMPTS_REMAINING_SECOND_PART}`;
    }

    // API error
    else if (statusCode === 500) {
      apiError = LOGIN_ERROR_API_ERROR;
    }

    // Trigger global error
    yield put({
      type: DIGIE_LOGIN_SET_ERROR,
      payload: {
        global: true,
        message: apiError
      }
    });
  }
}

// Watchers
export function* watchDigitalEngagement() {
  yield takeEvery(DIGIE_LOGIN_USER, handleLogin);
}
