// Types
import { SET_NOTIFICATION_BANNER } from "action-types/notifications";

// Defaults
const initialState = {
  notificationType: "",
  isVisible: false,
  delay: 3000
};

// Reducer
export default (state = initialState, inputData = {}) => {
  const { type, payload } = inputData;
  if (type === SET_NOTIFICATION_BANNER) {
    const { notificationType, isVisible, delay = 3000 } = payload;
    return { ...state, notificationType, isVisible, delay };
  }
  return state;
};
