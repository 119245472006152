// Modules
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

// External Icons
import { Icon } from "@sky-uk/toolkit-react";
import linearArrowUp from "@sky-uk/skycons/dist/linearArrowUp";
import linearArrowDown from "@sky-uk/skycons/dist/linearArrowDown";
import linearArrowRight from "@sky-uk/skycons/dist/linearArrowRight";

// css
import "./main-menu-list.scss";

const baseClass = "main-item";

export default ({
  label,
  url = "",
  items = [],
  setIsMobileMenuOpen,
  index
}) => {
  const [toggleSubMenu, setToggleSubMenu] = useState(false);

  // to check if the current browser url has the same name of sub menu item name to keep the sub menu list open when reopen the mobile menu navigation again
  useEffect(() => {
    const hasSubMenu =
      items.filter(
        item => item.url === window.location.pathname.toLocaleLowerCase()
      ).length > 0;
    setToggleSubMenu(hasSubMenu);
  }, [items]);

  return (
    <li className={`${baseClass}`} key={index}>
      <div className="main-menu-container" data-test-id="main-item-container">
        <NavLink
          data-test-id="main-link-item"
          className="main-link"
          to={url}
          onClick={() => {
            setIsMobileMenuOpen(false);
          }}
        >
          {label}
        </NavLink>
        <button
          data-test-id="arrow-icon"
          className="button-icon"
          onClick={() => {
            setToggleSubMenu(!toggleSubMenu);
          }}
        >
          {toggleSubMenu ? (
            <Icon
              data-test-id="arrow-up-icon"
              className={`${baseClass}__arrow_up`}
              svgSource={linearArrowUp}
            />
          ) : (
            <Icon
              data-test-id="arrow-down-icon"
              className={`${baseClass}__arrow_down`}
              svgSource={linearArrowDown}
            />
          )}
        </button>
      </div>
      {toggleSubMenu && (
        <div className="sub-menu-container" data-test-id="sub-menu-container">
          <ul className="sub-menu-list">
            {items.map((itemParam, indexParam) => (
              <li key={indexParam} className="sub-menu-item">
                <NavLink
                  className="sub-menu-link"
                  to={itemParam.url}
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                  }}
                >
                  {itemParam.label}
                  <Icon
                    className={`${baseClass}__arrow_right`}
                    svgSource={linearArrowRight}
                  />
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};
