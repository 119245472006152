// Modules
import React from "react";

// Element
const FooterVersion = ({
  copyright = "Sky Deutschland Fernsehen GmbH & Co. KG"
}) => {
  return (
    <div className="footer__disclaimer c-text-smallprint">
      &copy; {new Date().getFullYear()} {copyright}
      <br />(<small>{process.env.REACT_APP_VERSION}</small>)
    </div>
  );
};

export default FooterVersion;
