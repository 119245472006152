// Actions
import {
  DIGIE_LOGIN_RESET,
  DIGIE_LOGIN_SET_ERROR,
  DIGIE_LOGIN_SUCCESS,
  DIGIE_LOGIN_USER
} from "action-types/digital-engagement";

// Default
export const defaultState = {
  error: false,
  success: false
};

// Reducer
export default (state = defaultState, action = {}) => {
  const { payload, type } = action;

  switch (type) {
    case DIGIE_LOGIN_RESET:
      return defaultState;

    case DIGIE_LOGIN_SET_ERROR:
      return {
        ...state,
        success: false,
        error: payload
      };

    case DIGIE_LOGIN_SUCCESS:
      return {
        ...state,
        success: true
      };

    case DIGIE_LOGIN_USER:
      return {
        ...state,
        success: false,
        error: false
      };

    default:
      return state;
  }
};
