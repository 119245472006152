// Modules
import { call } from "redux-saga/effects";

// Store
import { errorLogger } from "store/sagas/logger";

// Generators
export function* errorHandler(error, preventSendingLogs) {
  // Start logging
  /* istanbul ignore else */
  if (!preventSendingLogs) {
    yield call(errorLogger, { error });
  }
}
