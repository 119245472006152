// IMPORTS
import React from "react";

// BASE CLASS
import "./modal.scss";

// COMPONENTS
import Backdrop from "components/modal/backdrop/backDrops";
import Spinner from "components/spinner";

// IMAGE
import { ReactComponent as Close } from "assets/icons/close.svg";

export default ({
  isOpen = false,
  children = null,
  loaderInverse = false,
  backgroundStyle = null,
  mainClose = false,
  closeModal,
  isLoading
}) => {
  const baseClass = "modal";

  return (
    isOpen && (
      <div className="scroll" data-test-id={`${baseClass}`}>
        <Backdrop backgroundStyle={backgroundStyle} show={isOpen}>
          {isLoading && (
            <Spinner
              id="1"
              inverse={loaderInverse}
              overlay="clear"
              size="small"
              alignCenter={true}
              complete={!isLoading}
              className="spinner"
            />
          )}
          {children && !isLoading && (
            <>
              {mainClose && (
                <div
                  className={`${baseClass}--header`}
                  onClick={closeModal}
                  data-test-id={`${baseClass}--header`}
                >
                  <Close className={`${baseClass}--header--icon`} />
                </div>
              )}

              <div className="modal">
                {React.Children.map(children || null, (child, i) => {
                  return <child.type {...child} key={i} />;
                })}
              </div>
            </>
          )}
        </Backdrop>
      </div>
    )
  );
};
