import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware, connectRouter } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import storage from "redux-persist/lib/storage";
import sagas from "./sagas/index.saga";
import reducers from "./reducers";

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const createRootReducer = historyParam =>
  combineReducers({
    router: connectRouter(historyParam),
    ...reducers
  });

// Setup redux persist
const persistConfig = {
  key: "root",
  storage,
  stateReconciler: hardSet
};
const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const middlewares = [routerMiddleware(history), sagaMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = preloadedState =>
  createStore(
    persistedReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

const store = configureStore();
const persistor = persistStore(store);
sagaMiddleware.run(sagas);

export { store, history, persistor };
