// Imports
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

// Components
import { Button, Checkbox } from "@sky-uk/toolkit-react";
import { Input } from "sky-mockups/input/index";
import ErrorMessage from "components/error-message/error-message";
import LoginLinks from "components/login-links/login-links";
import PIN from "components/pin/pin";

// Styles
import "./login-form.container.scss";

import {
  REG_FIELD_EMAIL,
  REG_FIELD_SKY_PIN,
  REG_FIELD_PASSWORD
} from "constants/registration";

import { LOGIN_FIELD_KEEP_LOGIN, LOGIN_CUSTOMER_NUMBER } from "constants/login";
import {
  DIGIE_LOGIN_ERROR_CUSTOMER_LOCKED,
  WELCOME_MESSAGE
} from "constants/digie-verification";
import { DigieMessagesWrapper } from "./digie-messages-wrapper";

// Helpers
import {
  handleChangeEmailHelper,
  handleSubmitHelper,
  createCorrectObject
} from "./login.form.helper.js";

// Utils
import { checkDigiE } from "utils/digital-engagement";
import validateLogin from "./login-form-validation";
import checkCustomerNumber from "./digie-login-form-validation";

// Defaults
const defaultFormFields = {};
[REG_FIELD_EMAIL, REG_FIELD_PASSWORD].forEach(field => {
  defaultFormFields[field] = {
    value: "",
    isValid: false
  };
});

[LOGIN_FIELD_KEEP_LOGIN].forEach(field => {
  defaultFormFields[field] = {
    value: false,
    isValid: true
  };
});

// Component
const LoginFormContainer = ({
  stayLogged,
  errorMessage,
  title = "Login",
  onLogin,
  labelSubmitButton = "Login",
  labels = {
    [REG_FIELD_SKY_PIN]: "Sky PIN",
    [REG_FIELD_EMAIL]: "E-mail",
    [REG_FIELD_PASSWORD]: "Passwort",
    [LOGIN_FIELD_KEEP_LOGIN]: "Eingeloggt bleiben",
    [LOGIN_CUSTOMER_NUMBER]: "E-Mail oder Kundennummer (10-stellig)"
  }
}) => {
  // State
  const [isDisabled, setIsDisabled] = useState(true);
  const [formValues, setFormFields] = useState(defaultFormFields);
  const [validationErrors, setValidationErrors] = useState();
  const focusInput = useRef(null);

  const isDigiE = checkDigiE();

  // Function
  const handleSubmit = event => {
    /* istanbul ignore else*/
    handleSubmitHelper(event);

    // Format value
    const values = {};
    createCorrectObject(formValues, isDigiE, values, LOGIN_FIELD_KEEP_LOGIN);

    onLogin(values);
  };

  const setFieldData = (name, value, isValid) => {
    setFormFields({
      ...formValues,
      [name]: {
        value,
        isValid
      }
    });
  };

  const handleChangeEmail = (value, { target: { name } }) => {
    handleChangeEmailHelper(
      value,
      name,
      isDigiE,
      checkCustomerNumber,
      validateLogin,
      setValidationErrors,
      setFieldData
    );
  };

  const handleChangePINOrPassword = (value, name, isValid) => {
    setFieldData(name, value, isValid);
  };

  const handleCheckboxClick = (_, checked) => {
    setFieldData(LOGIN_FIELD_KEEP_LOGIN, checked, true);
  };

  const handleOnBlur = ({ target: { value } }) => {
    /* istanbul ignore else*/
    if (!value.length) {
      setValidationErrors(null);
    }
  };

  // Hooks
  useEffect(() => {
    // Check button state
    let isValid = true;
    Object.keys(formValues).forEach(key => {
      if (!formValues[key].isValid) {
        isValid = false;
      }
    });
    setIsDisabled(!isValid);
  }, [formValues]);

  // Focus the element declared by inputRef
  useEffect(() => {
    focusInput.current.focus();
    // eslint-disable-next-line
  }, []);

  // CSS
  const baseClass = "login-form";

  return (
    <div className="o-layout">
      <div className="o-layout__item u-width-1/1">
        <div className={`${baseClass}__wrapper`}>
          <h3 className="c-heading-charlie u-text-center">{title}</h3>
          {/* For digie blocked message */}
          {isDigiE &&
            errorMessage &&
            errorMessage === DIGIE_LOGIN_ERROR_CUSTOMER_LOCKED &&
            DigieMessagesWrapper()}

          {/* For digie error messages */}
          {isDigiE &&
            errorMessage &&
            errorMessage !== DIGIE_LOGIN_ERROR_CUSTOMER_LOCKED && (
              <div className="u-text-center">
                <ErrorMessage message={errorMessage} />
              </div>
            )}
          <form
            className={baseClass}
            noValidate
            data-test-id="login-form"
            onSubmit={handleSubmit}
          >
            <div className="u-text-center">
              {!errorMessage && (
                <p className="u-text-center">{WELCOME_MESSAGE}</p>
              )}

              <Input
                inputRef={focusInput}
                cssClassName={`${baseClass}__input`}
                dataAttributes={{ "test-id": `input-${REG_FIELD_EMAIL}` }}
                id={REG_FIELD_EMAIL}
                data-test-id={REG_FIELD_EMAIL}
                key={REG_FIELD_EMAIL}
                labelText={
                  isDigiE
                    ? labels[LOGIN_CUSTOMER_NUMBER]
                    : labels[REG_FIELD_EMAIL]
                }
                onChange={handleChangeEmail}
                onBlur={handleOnBlur}
                message={validationErrors}
                stateModifier={
                  formValues[REG_FIELD_EMAIL].value.length &&
                  !formValues[REG_FIELD_EMAIL].isValid &&
                  "error"
                }
                type={isDigiE ? "text" : "email"}
                value={formValues[REG_FIELD_EMAIL].value.toLowerCase().trim()}
              />

              <div
                className={`${baseClass}__input ${baseClass}__input-password`}
              >
                <PIN
                  name={REG_FIELD_PASSWORD}
                  onChange={handleChangePINOrPassword}
                  onlyLengthValidation
                  title={labels[REG_FIELD_SKY_PIN]}
                  value={formValues[REG_FIELD_PASSWORD].value}
                />
              </div>

              <div className={`${baseClass}__keep-login`}>
                {stayLogged && (
                  <Checkbox
                    cssClassName={`${baseClass}__keep-login__checkbox`}
                    name={LOGIN_FIELD_KEEP_LOGIN}
                    id={LOGIN_FIELD_KEEP_LOGIN}
                    checked={formValues[LOGIN_FIELD_KEEP_LOGIN].value}
                    onChange={handleCheckboxClick}
                  >
                    <span>{labels[LOGIN_FIELD_KEEP_LOGIN]}</span>
                  </Checkbox>
                )}
              </div>

              <Button
                stateModifier={isDisabled && "disabled"}
                cssClassName={`${baseClass}__btn-login`}
                text={labelSubmitButton}
                type="submit"
              />
            </div>
            <LoginLinks />
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LoginFormContainer);
