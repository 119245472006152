// Global route
export const ROUTE_BASE = "";

// Digital engagement
export const ROUTE_DIGIE_BASE = `${ROUTE_BASE}/digie`;
export const ROUTE_DIGIE_VERIFICATION = `${ROUTE_DIGIE_BASE}/verification`;
export const ROUTE_DIGIE_SELFCARE_RESET = `https://www.sky.de/sky-pin-vergessen`;
export const ROUTE_DIGIE_SELFCARE_CONFIRMATION = `${ROUTE_DIGIE_BASE}/selfcare/confirmation`;
export const ROUTE_DIGIE_IMPRINT = `https://www.sky.de/impressum`;
export const ROUTE_DIGIE_DATA_PRIVACY = `${ROUTE_DIGIE_BASE}/dataprivacy`;
export const ROUTE_DIGIE_DATA_AGB = `${ROUTE_DIGIE_BASE}/agb`;
export const ROUTE_DIGIE_TERMS_OF_USE = `${ROUTE_DIGIE_BASE}/terms`;
