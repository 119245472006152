// Customer not found
const CUSTOMER_NOT_FOUND_COMUNITY_SKY_LINK =
  "<a href='https://community.sky.de' target='_blank'>community.sky.de</a>";
const CUSTOMER_NOT_FOUND_FACEBOOK_SKY =
  "<a href='https://www.facebook.com/SkyService' target='_blank'>facebook.de/SkyService</a>";
export const DIGIE_LOGIN_CUSTOMER_NOT_FOUND = `Wir konnten die von dir eingegebenen Daten leider nicht in unserem System finden. Hast du dich vielleicht vertippt? Solltest du Probleme mit dem Login haben, melde dich gerne über ${CUSTOMER_NOT_FOUND_COMUNITY_SKY_LINK} oder ${CUSTOMER_NOT_FOUND_FACEBOOK_SKY}.`;
// Customer not found

export const DIGIE_CUSTOMER_TWO_ATEMPTS_REMAINING =
  "Die von dir eingegebene Sky PIN stimmt nicht mit der PIN überein, die im System hinterlegt ist.";

export const DIGIE_CUSTOMER_TWO_ATEMPTS_REMAINING_FIRST_PART =
  "Die von dir eingegebene Sky PIN stimmt nicht mit der PIN überein, die im System hinterlegt ist. <br /><br />";

export const DIGIE_CUSTOMER_TWO_ATEMPTS_REMAINING_SECOND_PART =
  "<p>Du hast noch zwei Versuche. Nach dem dritten fehlgeschlagenen Versuch wird deine Sky PIN aus Sicherheitsgründen gesperrt.</p>";

export const DIGIE_LOGIN_ERROR_CUSTOMER_LAST_TRY_FIRST_PART =
  "Die von dir eingegebene Sky PIN stimmt nicht mit der PIN überein, die im System hinterlegt ist. <br /><br /> ";

export const DIGIE_LOGIN_ERROR_CUSTOMER_LAST_TRY_SECOND_PART =
  "<p>Du hast noch einen Versuch. Nach dem nächsten fehlgeschlagenen Versuch wird deine Sky PIN aus Sicherheitsgründen gesperrt.</p>";

export const DIGIE_LOGIN_RESET_LINK_TEXT = `[Sky PIN neu setzen](https://www.sky.de/sky-pin-vergessen)`;

// Customer is locked
const CUSTOMER_LOCKED_SKY_HELP_LINK =
  "<a href='https://www.sky.de/hilfecenter-130617?wkz=WHPS10&shurl=hilfe' target='_blank'>sky.de/hilfe</a>";
const CUSTOMER_LOCKED_COMMUNITY_SKY_LINK =
  "<a href='https://community.sky.de' target='_blank'>community.sky.de</a>";
const CUSTOMER_LOCKED_PIN_SKY_LINK =
  "<a href='https://www.sky.de/sky-pin-vergessen' target='_blank'>Sky PIN neu setzen</a>";

export const DIGIE_LOGIN_ERROR_CUSTOMER_LOCKED = `Deine Sky PIN wurde aus Sicherheitsgründen gesperrt, da du drei Mal eine falsche PIN eingegeben hast. Hier kannst du die ${CUSTOMER_LOCKED_PIN_SKY_LINK}. Solltest du Probleme mit dem Login haben, findest du weitere Informationen auf ${CUSTOMER_LOCKED_SKY_HELP_LINK} und auf ${CUSTOMER_LOCKED_COMMUNITY_SKY_LINK}.`;
// Customer is locked

export const DIGIE_ERROR_EMAIL = `Bitte gib mindestens 10 Ziffern oder eine gültige E-Mail-Adresse ein. Falls du noch keine Kundennummer erhalten oder Probleme beim Login hast, melde dich bitte über ${CUSTOMER_LOCKED_COMMUNITY_SKY_LINK} oder ${CUSTOMER_NOT_FOUND_FACEBOOK_SKY}.`;

export const DIGIE_COPYRIGHT = "Sky Deutschland Fernsehen GmbH & Co. KGc";

export const WELCOME_MESSAGE = `Willkommen beim Sky-Kundenservice. Damit wir dich als Sky Kunde/Kundin erkennen und optimal beraten können, logge dich bitte ein.`;

export const DIGIE_ERROR_SUSPENDED_CUSTOMER = `Sorry, wir konnten die Anmeldung nicht abschließen. Dein Account scheint nicht mehr aktiv zu sein. Um ohne Anmeldung Hilfe zu bekommen, schreibe uns am besten hier nochmal: ${CUSTOMER_NOT_FOUND_FACEBOOK_SKY}`;
