// Modules
import React from "react";
import { useDispatch } from "react-redux";

// Icons
import { ReactComponent as InfoIcon } from "assets/icons/linear-info.svg";

// Overlay
import { OVERLAY_CUSTOMER_NUMBER_INFO } from "action-types/watershed-overlay";

// Own CSS
import "./login-links.scss";

// Component
export default ({
  forgotPinPasswordLinkText = "Jetzt zurücksetzen",
  forgotPinText = "Sky PIN vergessen?",
  customerNumberLink = "Wo finde ich meine Kundennummer?",
  forgotCustomerNumberText = "Kundennummer vergessen? ",
  forgotCustomerNumberLinkText = "Jetzt anfordern",
  forgotCustomerNumberLinkLocation = "https://www.sky.de/kundennummer-vergessen",
  passwordDigieResetLink = "https://www.sky.de/sky-pin-vergessen"
}) => {
  // Init
  const dispatch = useDispatch();

  // CSS settings
  const baseClass = "login-form";

  const showCustomerNumberOverlay = evt => {
    evt.preventDefault();
    dispatch({ type: OVERLAY_CUSTOMER_NUMBER_INFO });
  };

  return (
    <div
      className={`${baseClass}__register c-text-body`}
      data-test-id="free-trial-button"
    >
      <p>
        {forgotPinText}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={passwordDigieResetLink}
          data-test-id="reset-link"
        >
          {forgotPinPasswordLinkText}
        </a>
      </p>
      <p>
        {forgotCustomerNumberText}{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={forgotCustomerNumberLinkLocation}
        >
          {forgotCustomerNumberLinkText}
        </a>
      </p>
      <p>
        <span className="numberLink" onClick={showCustomerNumberOverlay}>
          <InfoIcon className="infoIcon" />
          {customerNumberLink}
        </span>
      </p>
    </div>
  );
};
