import DOMpurify from "dompurify";

export const onlyNumber = e =>
  ((e.keyCode !== 8 && e.keyCode < 48) || e.keyCode > 57) && e.preventDefault();

export const isFunction = o => o && o.constructor && o.call && o.apply;

export const createMarkup = text => {
  return { __html: DOMpurify.sanitize(text) };
};

export const onEventDoNothing = e => e.preventDefault();

export const orderByWeight = function(a, b) {
  if (a.weight < b.weight) {
    return 1;
  }
  if (a.weight > b.weight) {
    return -1;
  }
  return 0;
};

export const checkDisabled = value => (value ? "disabled" : null);

// Create a hash code based on the input string and return it as a string
export const createHashCode = stringToHash => {
  for (var i = 0, h = 0; i < stringToHash.length; i++)
    h = (Math.imul(31, h) + stringToHash.charCodeAt(i)) | 0;
  return (h < 0 ? h * -1 : h).toString();
};
