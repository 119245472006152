// Modules
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import React from "react";

// Components
import Footer from "./components/footer/footer";
import Overlay from "./components/watershed-overlay/watershed-overlay";
import Modal from "components/modal/modal";
import Router from "Router";

// Container
import Header from "containers/header/main-menu/header";

// Store
import { waterShedOverlaySelector } from "sagas/selectors";

// CSS
import "App.scss";

// Custom hooks
import useLogger from "utils/hooks/useLogger";

// Utils
import { checkDigiE } from "utils/digital-engagement";
const cssBaseClass = "main-application";

const App = () => {
  // Init
  useLogger();

  // Redux
  const {
    overlayStatus,
    overlayTitle,
    overlayText,
    overlayButton,
    centerAlign
  } = useSelector(waterShedOverlaySelector);

  const digieVerification = checkDigiE();

  return (
    <div className={`${cssBaseClass}`}>
      <>
        {overlayStatus && (
          <Overlay
            title={overlayTitle}
            content={overlayText}
            button={overlayButton}
            center={centerAlign}
          />
        )}
        <Header userProfile={null} digieVerification={digieVerification} />
        <Modal />

        <div className="content">
          <Router />
        </div>
        <Footer />
      </>
    </div>
  );
};

export default withRouter(App);
