// Modules
import React from "react";

// Custom hooks
import { getConfig } from "utils/hooks/config";

// Components
import MainMenuItem from "containers/header/mobile-menu/main-menu-item/main-menu-item";
import MainMenuList from "containers/header/mobile-menu/main-menu-list/main-menu-list";

// External Icons
import { Icon } from "@sky-uk/toolkit-react";
import linearArrowRight from "@sky-uk/skycons/dist/linearArrowRight";

// css
import "./mobile-menu.scss";

const { url } = getConfig("helpcenter");
const baseClass = "mobile-main-navigation";

export default ({
  menuRoutes,
  digieVerification,
  setIsMobileMenuOpen,
  labels = {
    helpTitle: "Hilfe"
  }
}) => {
  return (
    <div className={`${baseClass}`}>
      <ul className={`${baseClass}__main-list`}>
        {menuRoutes.map((item, index) =>
          item.items ? (
            <MainMenuList
              data-test-id="main-menu-list"
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              key={index}
              item={item}
              label={item.label}
              url={item.url}
              items={item.items}
            />
          ) : (
            <MainMenuItem
              data-test-id="main-menu-item"
              label={item.label}
              url={item.url}
              key={item.label}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
            />
          )
        )}
        <li className="main-item">
          <a
            data-test-id="help-link"
            className="main-link external-link"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            {labels.helpTitle}
            <Icon
              className={`${baseClass}__arrow_right`}
              svgSource={linearArrowRight}
            />
          </a>
        </li>
      </ul>
    </div>
  );
};
