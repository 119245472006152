// Defaults
import {
  API_ERROR,
  API_VALIDATION,
  API_VALIDATION_LAST_TRY_ACCOUNT_BLOCKED
} from "./defaults";

// Messages
export const LOGIN_ERROR_GENERAL = API_VALIDATION;
export const LOGIN_ERROR_CUSTOMER_LOCKED =
  "Dein Zugang ist gesperrt. Du wurdest auf allen Geräten ausgeloggt.";
export const LOGIN_ERROR_CUSTOMER_LAST_TRY = API_VALIDATION_LAST_TRY_ACCOUNT_BLOCKED;
export const LOGIN_ERROR_CUSTOMER_LOCKED_LINK = "Jetzt Sky PIN zurück setzen";
export const LOGIN_ERROR_CUSTOMER_LOCKED_LINK_PASSWORD =
  "Jetzt Passwort zurück setzen";
export const LOGIN_ERROR_API_ERROR = API_ERROR;

export const INVALID_LOGIN_LINK =
  "Dieser Link ist nicht mehr gültig, da Du Dich bereits eingeloggt hast oder dein Anliegen bereits gelöst wurde.";

// Fields
export const LOGIN_FIELD_KEEP_LOGIN = "keepLogin";

export const LOGIN_CUSTOMER_NUMBER = "E-Mail oder Kundennummer (10-stellig)";

export const LOGIN__ERROR_CUSTOMER_TWO_ATEMPTS_REMAINING =
  "Deine Eingabe ist nicht korrekt.";

export const LOGIN_ERROR_CUSTOMER_NOT_FOUND =
  "Deine Eingabe ist nicht korrekt.";
